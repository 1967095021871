import React from "react"
import { Col } from "reactstrap"
import RenderBlock from "components/ui/block-text"

const ThirdItem = ({ title, description, index }) => {
  return (
    <Col sm="12" md="6" className="text-left">
      <h2>0{index}</h2>
      <h4>{title}</h4>
      <RenderBlock data={description} />
    </Col>
  )
}

export default ThirdItem
