import React, { useState, useEffect } from "react"
import BackgroundVideo from "react-background-video-player"
import { Container, Row, Col } from "reactstrap"
import AnchorLink from "react-anchor-link-smooth-scroll"
import RenderBlock from "components/ui/block-text"
import { graphql } from "gatsby"

const Hero = ({
  firstTitle,
  lastTitle,
  strikeTitle,
  videoUrl,
  cta,
  description,
}) => {
  const [flagIsVideoPause, setFlagIsVideoPause] = useState(false)
  const [player, setPlayer] = useState()

  const handleScroll = () => {
    if (window.scrollY > window.innerHeight) {
      setFlagIsVideoPause(true)
      player.pause()
    } else {
      if (flagIsVideoPause && player.isPaused()) {
        player.play()
      }
      setFlagIsVideoPause(false)
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  })

  return (
    <section className="home-banner">
      <div className="mask-hero" />
      <BackgroundVideo
        ref={p => setPlayer(p)}
        src={videoUrl}
        loop={false}
        autoPlay={true}
        volume={0}
      />
      <Container className="h-100">
        <Row className="h-100">
          <Col className="d-md-flex flex-md-column justify-content-md-center padding-top-90">
            <h1>
              <span className="text-red">{firstTitle}</span>
              <br />
              <span className="strike">{strikeTitle}</span> <br />
              {lastTitle}
            </h1>
            <div className="w-50-desktop">
              <RenderBlock data={description} className="padding-top-10 mb-0" />

              {cta?.enable && (
                <AnchorLink
                  href={cta?.link}
                  offset="70"
                  className="mt-4 padding-left-25 padding-right-25 btn btn-secondary btn-lg  btn-red"
                >
                  {cta?.text}
                </AnchorLink>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Hero

export const HeroSection = graphql`
  fragment HeroSection on SanityHeroSection {
    _rawDescription
    enable
    firstTitle
    lastTitle
    strikeTitle
    videoUrl
    cta {
      ...ctaComponent
    }
  }
`
