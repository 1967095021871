import { graphql } from "gatsby"
import React from "react"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"

import "../graphql/main"

import Layout from "components/layout"
import SEO from "components/seo"

import Hero from "components/sections/hero"
import Second from "components/sections/second"
import Third from "components/sections/third"
import Fourth from "components/sections/fourth"
import Fifth from "components/sections/fifth"
import Form from "components/sections/form"

import "../graphql/home"

const IndexPage = ({ data }) => {
  const {
    sanityPage: {
      tabs: { seo, hero, second, third, fourth, fifth, form },
    },
    sanityAppereance: {
      tabs: { menu, footer },
    },
  } = data

  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.GATSBY_GOGGLE_RECAPTCHA_SITE_KEY}
      language="es"
      scriptProps={{
        async: true,
        defer: true,
      }}
    >
      <Layout navbar={menu} footer={footer}>
        <SEO data={seo} />
        <Hero
          id="home"
          firstTitle={hero?.firstTitle}
          lastTitle={hero?.lastTitle}
          strikeTitle={hero?.strikeTitle}
          videoUrl={hero?.videoUrl}
          cta={hero?.cta}
          description={hero?._rawDescription}
        />
        <Second
          id=""
          title={second?._rawTitle}
          description={second?._rawDescription}
          items={second?.items}
        />
        <Third
          id=""
          title={third?._rawTitle}
          description={third?._rawDescription}
          items={third?.items}
          image={third?.image}
        />
        <Fourth
          id=""
          title={fourth?._rawTitle}
          description={fourth?._rawDescription}
        />
        <Fifth
          id=""
          title={fifth?._rawTitle}
          description={fifth?._rawDescription}
          items={fifth?.items}
        />
        <Form
          id="contact-us"
          title={form?._rawTitle}
          description={form?._rawDescription}
          formLink={fifth?.formLink}
        />
        <a
          href="https://recruitcrm.io/jobs/RVStaffing_jobs" 
          target="_blank"
          className="btn btn-outline-secondary btn-work-us"
        >
          Work With Us
        </a>
      </Layout>
    </GoogleReCaptchaProvider>
  )
}

export default IndexPage

export const IndexQuery = graphql`
  query HomeQuery {
    sanityPage(slug: { current: { eq: "home" } }) {
      ...IndexParts
    }

    sanityAppereance(sanityId: { eq: "Home" }) {
      tabs {
        menu {
          ...menuComponent
        }
        footer {
          link
          _rawDescription
          enable
          logo {
            ...imageComponent
          }
        }
      }
    }
  }
`
