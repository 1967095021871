import React from "react"
import { Container, Row, Col } from "reactstrap"
import Slider from "react-slick"
import { useMediaQueries } from "@react-hook/media-query"
import Image from "components/ui/image"
import RenderBlock from "components/ui/block-text"

const Fifth = ({ items, title, description }) => {
  const {
    matches: { isMobile, isTablet },
  } = useMediaQueries({
    isMobile: "only screen and (max-width: 580px)",
    isTablet: "only screen and (max-width: 850px) and (min-width: 581px) ",
  })

  const slidesQuantity = (isMobile && 2) || (isTablet && 2) || 3

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: slidesQuantity,
    slidesToScroll: slidesQuantity,
  }

  return (
    <section className="section-solution">
      <Container className="mb-md-5">
        <Row className="mb-md-5">
          <Col md={{ size: 8, offset: 2 }} className="text-md-center">
            <RenderBlock data={title} className="text-red text-uppercase" />
            <RenderBlock data={description} className="mx-md-4" />
          </Col>
        </Row>
        <Row className="items mx-lg-5 px-lg-5 mx-xl-5 px-xl-5">
          {isMobile ? (
            <Col sm="12" className="text-center">
              <Slider className="mt-5 mb-5 main--slider" {...settings}>
                {items.map(slide => (
                  <div className="slider--container">
                    <section className="mx-3 d-flex flex-column align-items-center justify-content-center">
                      <Image data={slide?.image} className="img-fluid pb-2" />
                      <p>{slide?.title}</p>
                    </section>
                  </div>
                ))}
              </Slider>
            </Col>
          ) : (
            <>
              {items.map((slide, key) => (
                <Col className="padding-bottom-30" sm="3" key={key}>
                  <section className="text-center">
                    <Image data={slide?.image} className="img-fluid pb-3" />
                    <p>{slide?.title}</p>
                  </section>
                </Col>
              ))}
            </>
          )}
        </Row>
      </Container>
    </section>
  )
}

export default Fifth

export const FifthSection = graphql`
  fragment FifthSection on SanityFifthSection {
    enable
    _rawTitle
    _rawDescription
    items {
      title
      image {
        ...imageComponent
      }
    }
    image {
      ...imageComponent
    }
  }
`
