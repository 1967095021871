import React, { useState } from "react"
import querystring from "querystring"
import { GoogleReCaptcha, useGoogleReCaptcha } from "react-google-recaptcha-v3"
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Button,
  Alert,
} from "reactstrap"
import RenderBlock from "components/ui/block-text"

const FormSection = ({ title, description }) => {
  const [statusEmail, setStatusEmail] = useState(null)
  const { executeRecaptcha } = useGoogleReCaptcha()

  const submitForm = ev => {
    ev.preventDefault()
    const form = ev.target
    const action = "contact_form"

    if (!executeRecaptcha) {
      return
    }
    executeRecaptcha(action).then(token => {
      if (form.interested.value === "") {
        return alert("Select which BPO solution you are interested in")
      }

      const formData = new FormData(form)
      formData.append("to", "webcontact@rvstaffing.com")
      formData.append("site", "https://www.rvstaffing.com/")
      let object = {
        action,
        token,
        key: process.env.GATSBY_GOGGLE_RECAPTCHA_SECRET_KEY,
      }
      formData.forEach((value, key) => {
        object = { ...object, [key]: value }
      })
      const query = querystring.stringify(object)
      const xhr = new XMLHttpRequest()
      xhr.open(
        "POST",
        "https://l6kg4l50gi.execute-api.us-east-1.amazonaws.com/send-forms"
      )
      xhr.setRequestHeader("Accept", "application/json")
      xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded")
      xhr.send(query)
      xhr.onreadystatechange = () => {
        if (xhr.readyState !== XMLHttpRequest.DONE) return
        if (xhr.status === 200) {
          form.reset()
          setStatusEmail("SUCCESS")
        } else {
          setStatusEmail("ERROR")
        }
      }
    })
  }

  return (
    <section className="section-contact-us" id="contact-us">
      <Container>
        <Row className="padding-bottom-25">
          <Col md={{ size: 6, offset: 3 }}>
            <RenderBlock
              data={title}
              className="text-red padding-bottom-10 text-md-center"
            />
            <RenderBlock data={description} className="text-md-center" />
          </Col>
        </Row>
        <Row>
          <Col sm="12">
            <Form onSubmit={submitForm}>
              <Row>
                <Col sm="12" md="6">
                  <FormGroup>
                    <Input type="text" name="name" placeholder="Name" />
                  </FormGroup>
                </Col>
                <Col sm="12" md="6">
                  <FormGroup>
                    <Input type="email" name="email" placeholder="E-mail" />
                  </FormGroup>
                </Col>
                <Col sm="12" md="6">
                  <FormGroup>
                    <Input
                      type="text"
                      name="company"
                      placeholder="Company Name"
                    />
                  </FormGroup>
                </Col>
                <Col sm="12" md="6">
                  <FormGroup>
                    <Input type="select" name="interested">
                      <option value="">
                        What BPO solutions are you interested in?
                      </option>
                      <option value="Front Office">Front Office</option>
                      <option value="Back Office">Back Office</option>
                      <option value="Executive Assitance">
                        Executive Assitance
                      </option>
                      <option value="Strategic Lead Engagement">
                        Strategic Lead Engagement
                      </option>
                      <option value="Other">Other</option>
                    </Input>
                  </FormGroup>
                </Col>
                <Col sm="12">
                  <FormGroup>
                    <Label>What goals could RVS help you achieve?</Label>
                    <Input type="textarea" name="subject" />
                  </FormGroup>
                </Col>
                <Col sm="12" className="pt-4 text-center">
                  <Button className="btn-red" size="lg" block type="submit">
                    Launch!
                  </Button>
                </Col>
              </Row>
              <GoogleReCaptcha />
            </Form>
            {statusEmail === "SUCCESS" && (
              <Alert color="success" className="mt-4">
                Thanks a lot. We will be in contact with you as soon as possible
              </Alert>
            )}
            {statusEmail === "ERROR" && (
              <Alert color="danger" className="mt-4">
                Ooops! There was an error. Try again.
              </Alert>
            )}
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default FormSection

export const FormFragment = graphql`
  fragment FormSection on SanityFormSection {
    enable
    _rawTitle
    _rawDescription
    formLink
  }
`
