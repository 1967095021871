import React, { useState } from "react"
import { Col } from "reactstrap"
import RenderBlock from "components/ui/block-text"
import Image from "components/ui/image"

const SecondItem = ({ title, description, image, imageHover }) => {
  const [isHoverImage, setIsHoverImage] = useState(false)
  const [current, setCurrent] = useState(image)

  const hoverImage = img => () => {
    setIsHoverImage(!isHoverImage)
    setCurrent(img)
  }

  return (
    <Col sm="12" md="4" className="text-md-center">
      <Image
        className="img-fluid mb-5"
        data={current}
        onMouseOver={hoverImage(imageHover ? imageHover : image)}
        onMouseOut={hoverImage(image)}
        style={{
          transform: isHoverImage ? "scale(1.1,1.1)" : "scale(1,1)",
          transition: "0.5s",
        }}
      />
      <h5>{title}</h5>
      <RenderBlock data={description} />
    </Col>
  )
}

export default SecondItem
