import React from "react"
import { Container, Row, Col } from "reactstrap"
import { useMediaQueries } from "@react-hook/media-query"
import Item from "./item"
import RenderBlock from "components/ui/block-text"
import Image from "components/ui/image"

import IconRV from "images/icon-rv.svg"

const Third = ({ items, image, title, description }) => {
  const {
    matches: { isMobile },
  } = useMediaQueries({
    isMobile: "only screen and (max-width: 580px)",
  })

  return (
    <section className="section-what-we-do" id="services">
      <Container>
        <Row>
          <Col md={{ size: 9, offset: 1 }} className="text-md-center">
            <img
              src={IconRV}
              className="img-fluid padding-bottom-10"
              alt="RV Dev"
            />
            <RenderBlock data={title} className="padding-bottom-30" />
            <RenderBlock
              data={description}
              className="mx-md-4 padding-bottom-30"
            />
          </Col>
        </Row>
        <Row>
          {isMobile ? (
            <Image
              data={image}
              className="img-fluid padding-bottom-25 rocket-mobile"
            />
          ) : (
            <Col sm="12" md="6">
              <Image data={image} className="img-fluid padding-bottom-25" />
            </Col>
          )}
          <Col sm="12" md="6">
            <Row className="text-left column-rocket">
              {items?.map((item, index) => (
                <Item
                  key={item?._key}
                  title={item?.title}
                  description={item?._rawDescription}
                  index={index + 1}
                />
              ))}
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Third

export const ThirdSection = graphql`
  fragment ThirdSection on SanityThirdSection {
    enable
    _rawTitle
    _rawDescription
    image {
      ...imageComponent
    }
    items {
      _key
      _rawDescription
      title
    }
  }
`
