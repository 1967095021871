import React from "react"
import { graphql } from "gatsby"
import { Container, Row, Col } from "reactstrap"
import Item from "./item"
import RenderBlock from "components/ui/block-text"

const Second = ({ items, title, description }) => {
  return (
    <section className="section-service" id="section-service">
      <Container>
        <Row className="mb-md-5">
          <Col md={{ size: 6, offset: 3 }} className="text-sm-center">
            <RenderBlock className="text-red" data={title} />
            <RenderBlock data={description} />
          </Col>
        </Row>
        <Row>
          {items?.map(item => (
            <Item
              key={item?._key}
              title={item?.title}
              description={item?._rawDescription}
              image={item?.image}
              imageHover={item?.imageHover}
            />
          ))}
        </Row>
      </Container>
    </section>
  )
}

export default Second

export const SecondSection = graphql`
  fragment SecondSection on SanitySecondSection {
    enable
    _rawTitle
    _rawDescription
    items {
      _key
      title
      _rawDescription
      image {
        ...imageComponent
      }
      imageHover {
        ...imageComponent
      }
    }
  }
`
