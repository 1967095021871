import React from "react"
import { Container, Row, Col } from "reactstrap"
import RenderBlock from "components/ui/block-text"

const Fourth = ({ title, description }) => {
  return (
    <section className="section-description">
      <Container className="h-100">
        <Row className="h-100">
          <Col
            md={{ size: 6 }}
            className="col-section-description d-md-flex flex-md-column pl-0"
          >
            <div>
              <RenderBlock className="padding-bottom-30" data={title} />
              <RenderBlock
                className="mx-md-4 mx-ms-0 mx-lg-0 mx-xl-0"
                data={description}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default Fourth

export const FourthSection = graphql`
  fragment FourthSection on SanityFourthSection {
    enable
    _rawDescription
    _rawTitle
    image {
      ...imageComponent
    }
  }
`
